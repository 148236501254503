import { graphql } from "gatsby"
import React, { useState } from "react"
import Layout from "../../../components/Layout"
import ItemBox from "../../../components/homepageV2/ItemBox"
import ComparisonTable from "../../../components/homepageV2/ComparisonTable"
import {
  getBackgroundImage,
  getCurrentLanguagePage,
} from "../../../plugins/common"
import Testimonial from "../../affiliate/components/Testimonial"
import Plan from "../../affiliate/components/Plan"
import ProductFeature from "../../affiliate/components/ProductFeature"
import IconTextBanner from "../../../components/homepageV2/IconTextBanner"
import VideoComponent from "./components/videoComponent"
import HrGuideCTA from "./components/hrGuideCTA"
import FaqInPage from "../../../components/FaqInPage"
import ArticleCard from "../../../components/ArticleCard"
import HomepageHeader from "./components/Header"

const HomepageB2BPage = ({ pageContext, data, location }) => {
  const { general, form } = pageContext

  const { strapiHomepageB2B } = data

  const [metaData, setMetaData] = useState(strapiHomepageB2B?.meta)

  const aleaToken = pageContext.general.aleaToken
  const comparisonTables = general.allStrapiComparisons?.nodes
  const ogImage = getBackgroundImage(strapiHomepageB2B.header?.image)
  const languageOptions = general.allStrapiLanguages?.nodes ?? "en"
  const lang = getCurrentLanguagePage(location, languageOptions)
  const enableFooterForm = strapiHomepageB2B.footerForm?.enableFooter
  const footerForm = {
    ...strapiHomepageB2B.footerForm,
    ...form.freeQuote,
  }
  const keyLabels = general.allStrapiRessource.nodes.find(
    node => node.locale === lang
  )

  const getComparisonTable = id => {
    if (comparisonTables && comparisonTables.length) {
      return comparisonTables.find(com => com.strapiId === id)
    }
    return null
  }

  return (
    <>
      <Layout
        props={pageContext.general}
        meta={metaData}
        localizations={strapiHomepageB2B.localizations.map(m => m.locale)}
        location={location}
        lang={strapiHomepageB2B.locale}
        hideNewsletter={true}
        token={aleaToken}
        footerForm={enableFooterForm ? footerForm : null}
        hideIcon={true}
        ogImage={ogImage}
        // guideFooter={nodes.guideFooter}
        hideLanguage={["fr"]}
        // ogImage={strapiHomepageB2B.footer.image.localFile.publicURL}
        isB2B={true}
      >
        <HomepageHeader
          data={strapiHomepageB2B.header}
          referee={{ heroTitle: null }}
          referralData={null}
          token={aleaToken}
          location={location}
          lang={strapiHomepageB2B.locale}
        />
        <div className="tw-overflow-hidden ">
          {strapiHomepageB2B.dynamicBody?.map((section, index) => {
            if (section.strapi_component === "homepage.horizontal-items")
              return <ItemBox key={index} lang={lang} data={section} />
            if (section.strapi_component === "affiliate.plan")
              return <Plan key={index} props={section} />
            if (section.strapi_component === "affiliate.product-feature")
              return (
                <ProductFeature key={index} data={section}></ProductFeature>
              )
            if (section.strapi_component === "homepage.horizontal-cta")
              return (
                <IconTextBanner
                  key={index}
                  lang={lang}
                  data={section}
                />
              )
            if (section.strapi_component === "layout.testimonial")
              return (
                <Testimonial key={index} props={section.customer_reviews} />
              )
            if (section.strapi_component === "layout.video-component")
              return (
                <VideoComponent
                  key={index}
                  title={section.title}
                  videoUrl={section.videoUrl}
                />
              )
            if (section.strapi_component === "homepage.article-cards")
              return (
                <div
                  key={index}
                  className="tw-px-[15%] md:tw-px-[5%] tw-py-[50px]"
                >
                  <h2 className=" ming tw-text-center tw-pb-4">
                    {section.title}
                  </h2>
                  <div className="tw-grid tw-grid-cols-3 md:tw-grid-cols-1 tw-gap-4 md:tw-gap-1">
                    {section.articles.length > 0 &&
                      section.articles.map((item, index) => {
                        return (
                          <div
                            key={`article-${index}`}
                            className="article mx-2"
                          >
                            <ArticleCard
                              article={item}
                              labels={keyLabels}
                              lang={lang}
                              type={"vertical"}
                            />
                          </div>
                        )
                      })}
                  </div>
                </div>
              )
            if (section.strapi_component === "layout.hr-guide-cta")
              return <HrGuideCTA key={index} data={section} />
            if (section.strapi_component === "layout.faq")
              return (
                <FaqInPage
                  key={index}
                  faq={section.questionAnswer}
                  meta={metaData}
                  setMeta={setMetaData}
                  title={section.sectionTitle}
                  lang={lang}
                  titleColor={` !tw-text-center`}
                  className={`!tw-px-[15%] !md:tw-px-[5%] tw-pb-16`}
                />
              )
            if (section.strapi_component === "homepage.comparison-table")
              return (
                <ComparisonTable
                  key={index}
                  lang={lang}
                  data={getComparisonTable(section.comparison.id)}
                />
              )
            return <></>
          })}
        </div>
      </Layout>
    </>
  )
}

export default HomepageB2BPage

export const query = graphql`
  query homepageB2BQuery($id: Int, $locale: String) {
    strapiHomepageB2B(strapiId: { eq: $id }, locale: { eq: $locale }) {
      title
      strapiId
      locale
      slug
      meta {
        title
        description
        noIndex
      }
      localizations {
        locale
        id
      }
      dynamicBody
      header {
        title
        subtitle
        formTitle
        emailLabel
        insuranceTypeLabel
        nameLabel
        phoneLabel
        submitLabel
        invalidFormMsg
        thanksMsg
        keywords {
          value
        }
        animateBeforeTitle
        productType {
          name
          value
        }
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        backgroundImage {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      footerForm {
        title
        submit
        enableHeader
        enableFooter
        submitBtnId
        products_types {
          weight
          value
          name
        }
      }
    }
  }
`
