import React from "react"

const VideoComponent = ({ title, videoUrl }) => {
  return (
    <div className="tw-px-[15%] md:tw-px-[5%] tw-py-[48px]">
      <h2 className=" ming tw-text-center">{title}</h2>
      {videoUrl && (
        <div className=" position-relative">
          <iframe
            className="position-absolue"
            title="video"
            style={{ top: "0", bottom: "0" }}
            width="100%"
            height="350"
            src={videoUrl}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      )}
    </div>
  )
}

export default VideoComponent
