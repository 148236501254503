import React from "react"
import { getExternalImg } from "../../plugins/common"
import { CustomMarkdown } from "../../plugins/sanitizehtml"
import Button from "../Button"
import * as style from "./IconTextBanner.module.scss"
const IconTextBanner = ({ data, lang, isFull }) => {
  const bgColor = data.bgColor === "sand" ? "bg-beige" : "bg-white"
  return (
    <div
      className={`flex ${style.iconTextBanner} ${bgColor} ${
        isFull ? "p-lg-0" : ""
      } flex-lg-row flex-column align-items-center justify-content-around`}
    >
      <div className={`flex align-items-center flex-lg-row flex-column`}>
        {data.image && (
          <img
            className={`${style.image}`}
            src={getExternalImg(data.image)}
            alt="alea"
          />
        )}
        {data.title && (
          <div className={`${style.title} ming lora-Bold`}>
            <CustomMarkdown html={data.title} color="copper"></CustomMarkdown>
          </div>
        )}
      </div>
      {data.cta && (
        <div className={``}>
          <Button
            lang={lang}
            type={data.cta.template}
            text={data.cta.buttonLabel}
            url={data.cta.url}
          />
        </div>
      )}
    </div>
  )
}

export default IconTextBanner
