import React from "react"
import Img from "../../../../components/Img"
import Button from "../../../../components/Button"

const HrGuideCTA = ({ data, lang }) => {
  const { title, subtitle, buttonLabel, image, buttonUrl } = data
  return (
    <div className=" tw-flex md:tw-flex-col  tw-justify-center tw-items-center tw-mb-16 tw-px-[15%] md:tw-px-[5%] tw-py-[50px] md:tw-py-0">
      <Img image={image} className={`tw-shadow-sm mb-3 tw-h-[400px] `} />
      <div className=" tw-ml-8 md:tw-ml-0 md:tw-text-center">
        <h2 className=" ming">{title}</h2>
        <div className=" tw-text-salmon tw-text-[18px] tw-pb-4">{subtitle}</div>
        <Button
          lang={lang}
          type={"primary"}
          text={buttonLabel}
          url={buttonUrl}
          className={` !tw-w-[80%] !md:tw-w-full !tw-bg-salmon`}
        />
      </div>
    </div>
  )
}

export default HrGuideCTA
