// extracted by mini-css-extract-plugin
export var alertRed = "Header-module--alert-red--671d5";
export var animateWords = "Header-module--animateWords--66e9a";
export var bgBarGrey = "Header-module--bg-bar-grey--b7cb3";
export var bgBeige = "Header-module--bg-beige--0ba63";
export var bgBeige2 = "Header-module--bg-beige2--16693";
export var bgCopper = "Header-module--bg-copper--e2712";
export var bgDaffodil = "Header-module--bg-daffodil--72432";
export var bgGreyText = "Header-module--bg-grey-text--e0222";
export var bgMing = "Header-module--bg-ming--03c99";
export var bgMint = "Header-module--bg-mint--47811";
export var bgRed = "Header-module--bg-red--9ae80";
export var bgRedTrans = "Header-module--bg-red-trans--3affe";
export var bgSand = "Header-module--bg-sand--d37df";
export var bgSoftSun = "Header-module--bg-softSun--4bd1d";
export var bgTeal = "Header-module--bg-teal--46655";
export var bgWhite = "Header-module--bg-white--d690a";
export var bgWomenSharp = "Header-module--bg-women-sharp--b8726";
export var blackText = "Header-module--black-text--4ae0a";
export var borderMing = "Header-module--border-ming--fca8a";
export var chinese = "Header-module--chinese--6d8ab";
export var copper = "Header-module--copper--e8885";
export var corners = "Header-module--corners--c116e";
export var cursorNormal = "Header-module--cursor-normal--435e4";
export var cursorPointer = "Header-module--cursor-pointer--c5b91";
export var customMarkdown = "Header-module--customMarkdown--64673";
export var dUnset = "Header-module--d-unset--30612";
export var darkGrey = "Header-module--dark-grey--2125c";
export var dash = "Header-module--dash--c013d";
export var dashBig = "Header-module--dashBig--0aae7";
export var deadSalmon = "Header-module--dead-salmon--219e6";
export var errorField = "Header-module--errorField--27a2a";
export var flex = "Header-module--flex--36a48";
export var flex1 = "Header-module--flex-1--a1339";
export var fontSize12 = "Header-module--font-size-12--cbd0e";
export var fontSize20 = "Header-module--font-size-20--d1a1d";
export var form = "Header-module--form--c489f";
export var formWrapper = "Header-module--formWrapper--8e4d0";
export var fr = "Header-module--fr--47dca";
export var greyPlaceholder = "Header-module--grey-placeholder--e812f";
export var greyText = "Header-module--grey-text--9e28e";
export var h1sizing = "Header-module--h1sizing--e2f55";
export var h2sizing = "Header-module--h2sizing--65971";
export var header = "Header-module--header--c689e";
export var hidden = "Header-module--hidden--c320f";
export var image = "Header-module--image--06a1c";
export var imageAni = "Header-module--imageAni--bbb77";
export var imageZoom = "Header-module--imageZoom--a4783";
export var imageZoomGatsby = "Header-module--imageZoomGatsby--f8e7b";
export var italic = "Header-module--italic--29681";
export var label = "Header-module--label--79591";
export var lightGrey = "Header-module--light-grey--a69ae";
export var logo = "Header-module--logo--e2fe2";
export var lora = "Header-module--lora--c5b19";
export var loraBold = "Header-module--lora-Bold--44bf1";
export var loraBoldItalic = "Header-module--lora-BoldItalic--e4a1f";
export var loraMedium = "Header-module--lora-Medium--4dade";
export var loraSemiBold = "Header-module--lora-SemiBold--f1cb8";
export var main = "Header-module--main--76b45";
export var ming = "Header-module--ming--2b510";
export var mingHover2 = "Header-module--ming-hover-2--5acdf";
export var modalOpen = "Header-module--modal-open--8068c";
export var noUnderline = "Header-module--no-underline--0bd92";
export var openSans = "Header-module--openSans--4df96";
export var openSansBold = "Header-module--openSans-Bold--3db8e";
export var openSansSemiBold = "Header-module--openSans-SemiBold--32be5";
export var pageGuide = "Header-module--page-guide--e988c";
export var popUp = "Header-module--popUp--f2d00";
export var popUpAni = "Header-module--pop-up-ani--de145";
export var quotation = "Header-module--quotation--21e8e";
export var rightCopper = "Header-module--rightCopper--50270";
export var rightLine = "Header-module--rightLine--4fd23";
export var round = "Header-module--round--47aac";
export var roundCorners = "Header-module--round-corners--da3ac";
export var row = "Header-module--row--846d2";
export var selectedType = "Header-module--selectedType--6ec5c";
export var shapes = "Header-module--shapes--dfbf4";
export var slogan = "Header-module--slogan--557d5";
export var softCorners = "Header-module--soft-corners--731b5";
export var softShadows = "Header-module--soft-shadows--2cc10";
export var softerCorners = "Header-module--softer-corners--4d874";
export var softyCorners = "Header-module--softy-corners--cb992";
export var submitBtn = "Header-module--submitBtn--19dca";
export var subtitle = "Header-module--subtitle--c4408";
export var tableShadow = "Header-module--table-shadow--decc7";
export var teal = "Header-module--teal--81080";
export var topNavShadow = "Header-module--top-nav-shadow--41c23";
export var topicArea = "Header-module--topicArea--1b0e3";
export var type = "Header-module--type--8286f";
export var uppercase = "Header-module--uppercase--d6787";
export var wFull = "Header-module--w-full--18cf5";
export var white = "Header-module--white--cb338";
export var womenRed = "Header-module--women-red--27626";
export var womenSharp = "Header-module--women-sharp--a8469";
export var word = "Header-module--word--354c4";