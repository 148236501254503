import React from "react"
import * as style from "./ItemBox.module.scss"
import { CustomMarkdown } from "../../plugins/sanitizehtml"
import { getExternalImg } from "../../plugins/common"
import Button from "../Button"
import Img from "../Img"
import Url from "../common/Url"

const ItemBox = ({ data, lang, full }) => {
  const { title, content, verticalItems, partners, bottomText, ctas } = data
  const bgColor = data.bgColor === "sand" ? "bg-beige" : "bg-white"
  const hasSubtitle = verticalItems?.find(v => v.subtitle)
  const onlyHasImage = item =>
    item.image &&
    !item.title &&
    !item.subtitle &&
    !item.content &&
    (!item.ctas || item.ctas.length === 0)
  return (
    <div
      className={`${style.itemBox} ${full ? `${style.full} mt-5` : ""
        } ${bgColor} ${full ? "text-start" : "text-center"}`}
    >
      {title && (
        <h2 className={`ming mb-5`}>
          <CustomMarkdown html={title} color="copper"></CustomMarkdown>
        </h2>
      )}
      <div className="container">
        <div className="row">
          {data.youtube?.enable && (
            <div className="col-lg col-md-12">
              <embed
                width="100%"
                height="315"
                src={data.youtube.url}
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></embed>
            </div>
          )}

          {content && (
            <p className={`col-lg col-md-12 mx-lg-5 mx-md-auto black-text`}>
              {content}
            </p>
          )}
        </div>
      </div>

      {((verticalItems && verticalItems.length > 0) ||
        (partners && partners.length > 0)) && (
          <div
            className={`flex ${style.verticalItemsWrapper
              } justify-content-center ${data.closer ? "" : "justify-content-lg-between"
              }`}
          >
            {partners?.length > 0
              ? partners.map((p, index) => (
                <div
                  key={index}
                  className={`${style.verticalItem} mb-3 ${style.onlyImage}} text-center flex-1 mb-lg-3`}
                >
                  {p.slug ? (
                    <Url url={"/partners/" + p.slug} lang={lang}>
                      <Img
                        image={p.logo}
                        className={`${style.bigImageWrapper} mb-2`}
                      />
                    </Url>
                  ) : (
                    <Img
                      key={index}
                      image={p.logo}
                      className={`${style.bigImageWrapper} mb-2`}
                    />
                  )}
                </div>
              ))
              : verticalItems.map((item, index) => (
                <div
                  key={index}
                  className={`${style.verticalItem
                    } text-center flex-1 mb-lg-5 ${onlyHasImage(item) ? `mb-0 ${style.onlyImage}` : "mb-5 mx-4"
                    }`}
                >
                  <div
                    className={`mb-4 ${item.title
                        ? style.smallImageWrapper
                        : style.bigImageWrapper
                      }`}
                  >
                    {item.image && (
                      // <img
                      //   className={`${
                      //     item.title ? style.smallImage : style.bigImage
                      //   }`}
                      //   src={getExternalImg(item.image)}
                      //   alt="alea"
                      // />
                      <Img
                        image={item.image}
                        className={`${item.title ? style.smallImage : style.bigImage
                          }`}
                      />
                    )}
                  </div>
                  {item.title && (
                    <h3 className={`ming openSans-Bold ${style.title}`}>
                      {item.title}
                    </h3>
                  )}
                  {hasSubtitle && (
                    <h3 className={`openSans-Bold copper ${style.title}`}>
                      {item.subtitle}
                    </h3>
                  )}
                  {item.content && (
                    <div className={`ming`}>
                      <CustomMarkdown
                        html={item.content}
                        color="copper"
                      ></CustomMarkdown>
                    </div>
                  )}
                  {item.ctas && item.ctas.length > 0 && (
                    <div className={`mt-4`}>
                      {item.ctas
                        .sort((a, b) => a.weight - b.weight)
                        .map((cta, index) => (
                          <div key={index} className={`mb-3`}>
                            <Button
                              lang={lang}
                              type={cta.template}
                              text={cta.buttonLabel}
                              url={cta.url}
                            />
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              ))}
          </div>
        )}
      {bottomText && (
        <div className={`lora-Bold ming ${style.bottomText}`}>
          <CustomMarkdown html={bottomText} color="copper"></CustomMarkdown>
        </div>
      )}
      {ctas && ctas.length > 0 && (
        <div className={`mt-4 flex justify-content-center`}>
          {ctas.map((cta, index) => (
            <div key={index} className={`mx-4`}>
              <Button
                lang={lang}
                type={cta.template}
                text={cta.buttonLabel}
                url={cta.url}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default ItemBox
