// extracted by mini-css-extract-plugin
export var alertRed = "ItemBox-module--alert-red--ba72f";
export var bgBarGrey = "ItemBox-module--bg-bar-grey--7cc61";
export var bgBeige = "ItemBox-module--bg-beige--40a46";
export var bgBeige2 = "ItemBox-module--bg-beige2--a3a48";
export var bgCopper = "ItemBox-module--bg-copper--0ec90";
export var bgDaffodil = "ItemBox-module--bg-daffodil--c433a";
export var bgGreyText = "ItemBox-module--bg-grey-text--ddc08";
export var bgMing = "ItemBox-module--bg-ming--2a58a";
export var bgMint = "ItemBox-module--bg-mint--da542";
export var bgRed = "ItemBox-module--bg-red--545ab";
export var bgRedTrans = "ItemBox-module--bg-red-trans--4b63c";
export var bgSand = "ItemBox-module--bg-sand--2549c";
export var bgSoftSun = "ItemBox-module--bg-softSun--236c8";
export var bgTeal = "ItemBox-module--bg-teal--e01d1";
export var bgWhite = "ItemBox-module--bg-white--2bd9a";
export var bgWomenSharp = "ItemBox-module--bg-women-sharp--8b400";
export var bigImage = "ItemBox-module--bigImage--9e5c0";
export var bigImageWrapper = "ItemBox-module--bigImageWrapper--cb420";
export var blackText = "ItemBox-module--black-text--6170b";
export var borderMing = "ItemBox-module--border-ming--27270";
export var bottomText = "ItemBox-module--bottomText--c54a2";
export var copper = "ItemBox-module--copper--4cc65";
export var corners = "ItemBox-module--corners--18901";
export var cursorNormal = "ItemBox-module--cursor-normal--dce51";
export var cursorPointer = "ItemBox-module--cursor-pointer--e6a58";
export var customMarkdown = "ItemBox-module--customMarkdown--f7932";
export var dUnset = "ItemBox-module--d-unset--40109";
export var darkGrey = "ItemBox-module--dark-grey--7fb1b";
export var dash = "ItemBox-module--dash--2f64b";
export var dashBig = "ItemBox-module--dashBig--3ddaf";
export var deadSalmon = "ItemBox-module--dead-salmon--4f0c5";
export var flex = "ItemBox-module--flex--f7b33";
export var flex1 = "ItemBox-module--flex-1--bc723";
export var fontSize12 = "ItemBox-module--font-size-12--275a6";
export var fontSize20 = "ItemBox-module--font-size-20--33f19";
export var full = "ItemBox-module--full--66928";
export var greyPlaceholder = "ItemBox-module--grey-placeholder--f2e7d";
export var greyText = "ItemBox-module--grey-text--4718c";
export var h1sizing = "ItemBox-module--h1sizing--92481";
export var h2sizing = "ItemBox-module--h2sizing--65751";
export var hidden = "ItemBox-module--hidden--9cad3";
export var imageAni = "ItemBox-module--imageAni--872f5";
export var imageZoom = "ItemBox-module--imageZoom--13047";
export var imageZoomGatsby = "ItemBox-module--imageZoomGatsby--ea34d";
export var italic = "ItemBox-module--italic--937de";
export var itemBox = "ItemBox-module--itemBox--179b0";
export var lightGrey = "ItemBox-module--light-grey--06895";
export var logo = "ItemBox-module--logo--89b86";
export var lora = "ItemBox-module--lora--f49bf";
export var loraBold = "ItemBox-module--lora-Bold--80931";
export var loraBoldItalic = "ItemBox-module--lora-BoldItalic--42cd7";
export var loraMedium = "ItemBox-module--lora-Medium--e6ca7";
export var loraSemiBold = "ItemBox-module--lora-SemiBold--920e5";
export var main = "ItemBox-module--main--cd2ad";
export var ming = "ItemBox-module--ming--1ae4b";
export var mingHover2 = "ItemBox-module--ming-hover-2--d818a";
export var modalOpen = "ItemBox-module--modal-open--b2a68";
export var noUnderline = "ItemBox-module--no-underline--5cea2";
export var onlyImage = "ItemBox-module--onlyImage--5c01f";
export var openSans = "ItemBox-module--openSans--df430";
export var openSansBold = "ItemBox-module--openSans-Bold--c298e";
export var openSansSemiBold = "ItemBox-module--openSans-SemiBold--59b09";
export var pageGuide = "ItemBox-module--page-guide--4cdac";
export var popUp = "ItemBox-module--popUp--f0d64";
export var popUpAni = "ItemBox-module--pop-up-ani--0ce80";
export var quotation = "ItemBox-module--quotation--6c09f";
export var round = "ItemBox-module--round--7aecb";
export var roundCorners = "ItemBox-module--round-corners--16911";
export var smallImage = "ItemBox-module--smallImage--e4cea";
export var smallImageWrapper = "ItemBox-module--smallImageWrapper--72eee";
export var softCorners = "ItemBox-module--soft-corners--6fefe";
export var softShadows = "ItemBox-module--soft-shadows--9c534";
export var softerCorners = "ItemBox-module--softer-corners--19a18";
export var softyCorners = "ItemBox-module--softy-corners--83507";
export var tableShadow = "ItemBox-module--table-shadow--c6af2";
export var teal = "ItemBox-module--teal--be323";
export var title = "ItemBox-module--title--db169";
export var topNavShadow = "ItemBox-module--top-nav-shadow--ec53d";
export var topicArea = "ItemBox-module--topicArea--09191";
export var uppercase = "ItemBox-module--uppercase--c089a";
export var verticalItem = "ItemBox-module--verticalItem--7dca0";
export var verticalItemsWrapper = "ItemBox-module--verticalItemsWrapper--41a3b";
export var wFull = "ItemBox-module--w-full--0e452";
export var white = "ItemBox-module--white--75a64";
export var womenRed = "ItemBox-module--women-red--e360d";
export var womenSharp = "ItemBox-module--women-sharp--c5ee1";